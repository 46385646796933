import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import MoslemeTable from "components/Table"

import {
    handleGetFileRelasi,
    handleGetJamaahByRoomList,
    handleGetListRelasi,
    handleGetRoomlistByPackage,
    handleGetSetRoomList,
    handleUpdatedRelasi,
    handleUpdatedRoomList,
  } from "stores/actions/paket/roomlist";

import { handleError } from "stores/actions/errorGeneral";
import Avatar from "components/avatar";
import { toCapital } from "utils/helpers";

import RoomBadge from "./components/RoomBadge";
import { Edit } from "iconsax-react";
import Badge from "components/badge";

import './index.css'
import Popup from "components/popup";
import ChoseRelation from "./components/ChoseRelation";
import Buttons from "components/buttons";
import SetRoom from "./SetRoom";

const JamaahList = ({
    keyword,
    keywordDebounce,
    roomSelectedID,
    roomTypeSelected,
    onUpdatedRoomlist,
    packageID
}) => {
    const params = useParams()
    const dispatch = useDispatch()

    const auth = useSelector(({ auth }) => auth)
    const permissions = auth?.user?.permissions;

    const [listJamaahRoomlist, setListJamaahRoomlist] = useState([])
    const [isGettingJamaah, setIsGettingJamaah] = useState(false)
    
    const [currentpage, setCurrentpage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [total, setTotal] = useState(0)

    const [openSetRelasi, setOpenSetRelasi] = useState(false)
    const [choiceRelasi, setChoiceRelasi] = useState("Jamaah Sendiri")
    const [roomListSelected, setRoomListSelected] = useState({})

    const [filterRoomType, setFilterRoomType] = useState('all')
    const [filterRoomlistID, setFilterRoomlistID] = useState(null)

    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [selectedRecord, setSelectedRecord] = useState([])
    const [selectedRoomType, setSelectedRoomType] = useState(null)

    const [openSetRoom, setOpenSetRoom] = useState(false)
    
    const pagination = {
      showSizeChanger: true,
      total: total,
      current: currentpage,
      pageSize: perPage,
      defaultPageSize: 10,
      pageSizeOptions: ["10", "20", "50", "100"],
    };

    const onSizeChange = (currentpage, pageSize) => {
        setCurrentpage(currentpage)
        setPerPage(pageSize)
    }

    const handleMaxJamaahOnRoomType = (roomType) => {
      if (roomType === 'quad') {
        return 4
      }
      if (roomType === 'tripple') {
        return 3
      }
      if (roomType === 'double') {
        return 2
      }
      if (roomType === 'single') {
        return 1
      }
    }

    const handleGetJamaahRoomlist = () => {

      const payload = {
        page: currentpage,
        per_page: perPage,
        room_type: filterRoomType,
        room_list_id: filterRoomlistID,
        search: keyword
      }

      setIsGettingJamaah(true)
      dispatch(handleGetJamaahByRoomList(
          packageID,
          payload,
          (response) => {
              setIsGettingJamaah(false)
              setListJamaahRoomlist(response.data.data)
          },
          (err) => {
              setIsGettingJamaah(false)
              dispatch(handleError(err.data))
          }
      ))
    }

    const handleSelectingRow = (record, selected) => {
        if (selected) {

          // set selected room type
          if (selectedRowKeys.length === 0) {
            let roomType = record.jamaah.room_type
            roomType = roomType.replace('_bussiness', '')
            setSelectedRoomType(roomType)
          }

          // set selected room
          setSelectedRowKeys([...selectedRowKeys, record.id])
          setSelectedRecord([...selectedRecord, record])
        } else {

          // remove
          setSelectedRowKeys(selectedRowKeys.filter((key) => key !== record.id))
          setSelectedRecord(selectedRecord.filter((data) => data.id !== record.id))
        }
    }

    const handleResetSelected = () => {
      setSelectedRowKeys([])
      setSelectedRecord([])
      setSelectedRoomType(null)
    }

    const columns = [
        {
            title: "Foto",
            width: "75px",
            align: "center",
            render: (record) => (
              <>
                <div className="ml-4">
                  <Avatar value={record.jamaah} />
                </div>
              </>
            ),
        },
        {
            title: "Nama",
            dataIndex: "jamaah",
            render: (record) => (
              <>
                {toCapital(record.title)}. {record.name?.toUpperCase()}
              </>
            ),
        },
        {
            title: "Kamar",
            dataIndex: "jamaah",
            render: (record) => (
              <>
                <RoomBadge 
                  roomType={record.room_type}
                  roomNumber={record.room_number}
                  />
              </>
            )
        },
        {
          title: "Umur",
          align: "center",
          render: (data) => <>
            <div>
              {data?.jamaah?.age ? data?.jamaah?.age : '-'}
            </div>
          </>,
        },
        // {
        //   title: "Relasi",
        //   dataIndex: "jamaah",
        //   render: (record, data) => (
        //     <>
        //       <div className="bg-red-5 rounded-lg py-2 px-4 text-xs inline-flex gap-2">
        //         <span>{record.relasi}</span>
        //         {auth.user.access_data === 'all_branches' && (
        //           <>
        //             <div 
        //               onClick={() => {
        //                 setOpenSetRelasi(true)
        //                 setChoiceRelasi(record.relasi)
        //                 setRoomListSelected(data)
        //               }}
        //               className="cursor-pointer">
        //               <Edit 
        //                 size={16}
        //                 />
        //             </div>
        //           </>
        //         )}
        //       </div>
        //     </>
        //   )
        // },

        {
          title: "Nomor Order",
          dataIndex: "order_number",
          render: (record) => (
            <>
              <strong>#{record}</strong>
            </>
          )
        },
        {
          title: "Catatan Request",
          render: (data) => <>
            <div>
              {data.additionals.length > 0 ? (
                <>
                  {data.additionals.map((additional, index) => (
                  <div key={index}>
                    {additional.name}{index < data.additionals.length - 1 && ' •'}
                  </div>
                ))}
                </>
              ) : (
                <>
                  -
                </>
              )}
            </div>
          </>,
        },
        {
          title: "Catatan Tambahan",
          render: (data) => <>
            <div>
              {data.coupons.length > 0 ? (
                <>
                  {data.coupons.map((coupon, index) => (
                  <div key={index}>
                    {coupon.code_coupon}{index < data.coupons.length - 1 && ' •'}
                  </div>
                ))}
                </>
              ) : (
                <>
                  -
                </>
              )}
            </div>
          </>,
        },
        {
          title: "Cabang",
          dataIndex: "jamaah",
          render: (record) => (
            <>
              <Badge 
                label={record.branch?.name?.toUpperCase()}
                backgroundColor={record.branch?.bg_color}
                color={record.branch?.text_color}
                />
            </>
          )
        }
    ]

    const rowSelection = {
      selectedRowKeys: selectedRowKeys,
      getCheckboxProps: (record) => ({
        disabled: (selectedRoomType && !record.jamaah.room_type?.includes(selectedRoomType))
          || (selectedRowKeys.length >= handleMaxJamaahOnRoomType(record.jamaah.room_type) && !selectedRowKeys?.includes(record.id))
          || auth.user?.access_data === 'self_branch' || !permissions.includes('update_roomlist')
        ,
        // Column configuration not to be checked
        // roomType: record.jamaah.room_type,
      }),
      hideSelectAll: true,
      onSelect: handleSelectingRow
    };

    useEffect(() => {
      handleGetJamaahRoomlist()
    }, [])

    useEffect(() => {
      setFilterRoomlistID(null)
      setFilterRoomType(roomTypeSelected)
    }, [roomTypeSelected])

    useEffect(() => {
      setFilterRoomlistID(roomSelectedID)
    }, [roomSelectedID])

    useEffect(() => {
      handleGetJamaahRoomlist()
    }, [filterRoomType, filterRoomlistID, keywordDebounce])

    useEffect(() => {
      
      if(selectedRowKeys.length === 0) {
        setSelectedRoomType(null)
      }

    }, [selectedRowKeys, selectedRecord])

    return (
        <>
          <Popup
            open={openSetRelasi}
            handleClose={() => {
              setOpenSetRelasi(false);
            }}
            title="Atur Relasi"
            width={"600px"}
            containerClass="z-[1000]"
          >
            <ChoseRelation 
              choiceRelasi={choiceRelasi}
              roomList={roomListSelected}
              onUpdated={() => {
                handleGetJamaahRoomlist()
                setOpenSetRelasi(false)
              }}
              />
          </Popup>

          <Popup
            open={openSetRoom}
            handleClose={() => {
              setOpenSetRoom(false);
            }}
            title="Atur Anggota Kamar"
            width={"1024px"}
            containerClass="z-[1000]"
            >
            <SetRoom 
              jamaahSelected={selectedRecord}
              packageID={packageID}
              onSuccess={() => {
                handleGetJamaahRoomlist()
                setOpenSetRoom(false)
                onUpdatedRoomlist()
                handleResetSelected()
              }}
              />
          </Popup>

          {!!selectedRowKeys.length && (
            <>
              <div className="w-full py-3 px-4 flex justify-between border-b items-center">
                <div className="flex flex-col gap-2">
                  <span className="text-xs text-gray-600">
                    Jamaah Terpilih
                  </span>
                  <span className="text-sm font-semibold">
                    {selectedRowKeys.length} {`(${toCapital(selectedRoomType)})`}
                  </span>
                </div>
                <div className="flex gap-4">
                  <Buttons
                    text="Reset Checklist"
                    customClass="btn-secondary btn-small"
                    onClick={handleResetSelected}
                    />
                  <Buttons
                    text="Atur Roomlist"
                    customClass="btn-outline btn-small"
                    onClick={() => {
                      setOpenSetRoom(true)
                    }}
                    />
                </div>
              </div>
            </>
          )}
          <MoslemeTable
            columns={columns}
            data={listJamaahRoomlist}
            withSelection={true}
            selectionType={"checkbox"}
            rowSelection={rowSelection}
            rowKey={(record) => record.id}
            loading={isGettingJamaah}
            pagination={pagination}
            customClass={`${selectedRowKeys.length ? "table-roomlist-selected" : "table-roomlist"}`}
            withPagination={false}
            scroll={{ y: '300px' }}
            customPaginationClass="pr-4"
            onPageChange={(page) => setCurrentpage(page)}
            onSizeChange={(currentpage, pageSize) =>
              onSizeChange(currentpage, pageSize)
            }
          />
        </>
    )
}

export default JamaahList