import React from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setDataUpload, setDataUploadMulti } from "stores/actions/uploadFile";
import uuid from "react-uuid";
import { IcCamera, IcDeleteCircle } from "assets/icons/dashboard";
import Buttons from "components/buttons";
import ImageField from "components/image_field";
import { Input } from "antd";
import DatePicker from "components/datepicker/DatePicker";
import RichTextEditor from "components/richTextEditor";
import Scroll from "components/scroll";
import dayjs from 'dayjs';

const { TextArea } = Input;

const EditItinenary = ({ inserItenary, isLoadingAction, itenary }) => {
  const dispatch = useDispatch();
  const { dataUpload, dataMultiple } = useSelector((state) => state.uploadFile);

  const formik = useFormik({
    initialValues: {
      date: itenary?.date || null,
      deskripsi: itenary?.description || "",
      rute: itenary?.rute || [],
      input: "",
    },
  });

  React.useEffect(() => {
    if (!itenary) return;

    const { gallery, thumbnail } = itenary;

    const galleryData = gallery.map(data => ({
      id: data?.id,
      fileName: data.itinerarie_id,
      path: data.link,
      name: "galery_foto",
      type: data.type,
    }));

    dispatch(setDataUploadMulti(galleryData));

    dispatch(setDataUpload([
      ...dataUpload,
      {
        name: "thumbnail",
        path: thumbnail,
        fileName: "thumbnail",
        id: uuid(),
      },
    ]));

    console.log('Itenary:', itenary);
  }, [itenary, dispatch]);

  const handleSave = () => {
    inserItenary({
      id: itenary?.id_itinerarie,
      date: formik.values.date,
      rute: formik.values.rute,
      description: formik.values.deskripsi,
      gallery: dataMultiple?.map((item) => ({
        link: item.path,
        type: item.type,
        id: item?.id,
      })),
      thumbnail: dataUpload[0].path,
    });
  };

  return (
    <main className="rounded-2xl bg-white border-solid border-[#E0E0E0] border-[1px]">

        <div className="m-[16px] flex flex-col justify-start gap-y-[16px]">
          <Scroll height="450px">
            <div className="w-full">
              <div className="mb-2 font-semibold">Tanggal Perjalanan</div>
              <div className="w-full flex flex-row">
                <DatePicker
                  placeholder={formik.values.date ? formik.values.date : "Pilih Tanggal"}
                  onChange={(val) => formik.setFieldValue("date", val)}
                  containerClass="!w-full"
                  customClass="!w-full"
                  value={dayjs(formik.values.date, "DD-MM-YYYY", "YYYY-MM-DD")}
                />
              </div>
            </div>
            <section className="grid-cols-2 grid gap-x-5">
              <div>
                <ImageField
                  label="Thumbnail"
                  customWrapper=""
                  customContainer="!w-full"
                  width="451px"
                  height="300px"
                  src={IcCamera}
                  isMulti={false}
                  status={false}
                  setFile={(file) => {
                    // Handle file upload
                  }}
                  name="thumbnail"
                />
              </div>
              <div>
                <RichTextEditor
                  data={{ value: formik.values.deskripsi }}
                  onChange={(e) => formik.setFieldValue("deskripsi", e)}
                  label="Deskripsi"
                />
              </div>
            </section>
            <section className="bg-white p-[16px] border-[#E0E0E0] border-dashed border-[1px] rounded-2xl">
              <div className="font-semibold mb-2">Rute</div>
              <div className="flex items-center justify-between relative overflow-hidden rounded-[12px] bg-[#F2F2F2]">
                <input
                  placeholder="Masukan nama kota"
                  name="input"
                  className="flex-1 !ml-0 outline-none !py-[16px] !px-[16px] bg-[#F2F2F2] overflow-hidden"
                  value={formik.values.input}
                  onChange={(e) => formik.setFieldValue("input", e.target.value)}
                />
                <Buttons
                  text="Tambahkan"
                  customClass="btn-primary !w-fit btn-small outline-none absolute top-[10px] right-[16px]"
                  onClick={() => {
                    formik.setFieldValue("rute", [...formik.values.rute, formik.values.input]);
                    formik.setFieldValue("input", "");
                  }}
                />
              </div>
              <div className="flex justify-start items-start mt-5">
                {formik.values.rute.map((item, idx) => (
                  <div className="flex items-center" key={idx}>
                    <div className="flex gap-x-2 items-center justify-start bg-gray-1 rounded-lg py-1 px-2">
                      <div>{item}</div>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          const newRute = formik.values.rute.filter((_, index) => index !== idx);
                          formik.setFieldValue("rute", newRute);
                        }}
                      >
                        <img alt="delete-icon" src={IcDeleteCircle} />
                      </div>
                    </div>
                    {idx !== formik.values.rute.length - 1 && (
                      <span className="ln-blue w-5 h-1 mx-2"></span>
                    )}
                  </div>
                ))}
              </div>
            </section>
            <div>
              <ImageField
                label="Tambahkan Foto"
                isMulti={true}
                customContainer="!w-auto !rounded-2xl !h-[452px]"
                setFile={(a) => console.log(a)}
                name="galery_foto"
                customImageContainer="!w-[250px] h-full"
              />
            </div>
            <div className="divider"></div>
          </Scroll>
          <div className="flex justify-end items-end my-4 mr-4">
            <Buttons
              text="Simpan"
              loading={isLoadingAction}
              disabled={formik.isSubmitting || !formik.isValid}
              customClass="btn-primary btn-large"
              onClick={handleSave}
            />
          </div>
        </div>
    </main>
  );
};

export default EditItinenary;
