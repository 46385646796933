
import Echo from "laravel-echo";

window.Pusher = require('pusher-js');

const options = {

  broadcaster: 'pusher',
  key: process.env.REACT_APP_PUSHER_APP_KEY,
  wsHost: process.env.REACT_APP_PUSHER_HOST,
  wsPort: 6001,
  cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
  forceTLS: false,
  disableStats: true,
  encrypted: true,
  enabledTransports: ['ws', 'wss'],

    // broadcaster: "pusher",
    // key: "d4b9af39550bd7832778",
    // cluster: "ap2",
    // forceTLS: true,
    // encrypted: false,
    // //authEndpoint is your apiUrl + /broadcasting/auth
    // authEndpoint: "https://api.abc.com/broadcasting/auth",
    // // As I'm using JWT tokens, I need to manually set up the headers.
    // auth: {
    //     headers: {
    //         Authorization: "Bearer " + cookies.get("access_token"),
    //         Accept: "application/json"
    //     }
    // }
};

const echo = new Echo(options);

echo.connector.pusher.connection.bind('error', (error) => {
  console.error('WebSocket Error:', error);
});

export default echo