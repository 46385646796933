 
import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector }  from "react-redux";
import { useParams } from "react-router-dom";

import Badge from "../../../../components/badge";
import Buttons from "../../../../components/buttons";
import Progressbars from "../../../../components/progressbars";
import Tab from "../../../../components/tab";

import IconTrash from "assets/icons/trash.svg";
import IconEdit from "../../../../assets/icons/edit.svg";
import IconSMS from "../../../../assets/icons/sms.svg";
import FollowUpSenderIcon from "assets/icons/followup-sender.svg";
import IconWhatsapp from "../../../../assets/icons/whatsapp.svg";
import AdditionalTable from "./AdditionalTable";
import JamaahEquipment from "./JamaahEquipment";

import { handleSendFollowupToJamaahOrder } from "utils/api/booking";

import { handleGetDataJamaah, handleGetDetailOrder } from "stores/actions/booking";

import { 
  handleGetDataManifestAPI, 
  handleGetDataAdditionalsAPI, 
  handleGetDataEquipmentsAPI, 
  handleDeleteAdditionalByJamaahOrderAPI,
  handleCancelJamaahAPI,
  updateStatusSeatAPI
} from "utils/api/booking";

import { handleError, handleSuccess } from "stores/actions/errorGeneral";

import Popup from "components/popup";
import EditAdditionalForm from "./Additionals/EditAdditionalForm";
import Alert from "components/popup/alert";
import Uploadarea from "components/Uploadarea";
import ManifestForm from "./ManifestForm";
import EditRoom from "./RoomFrom/EditRoom";
import { Eye } from "iconsax-react";
import Switch from "components/form/switch";

const DataJamaah = ({
    jamaahData,
    isExpand
}) => {
    const dispatch = useDispatch()
    const params = useParams()
    const today = new Date();
    
    const [dataManifest, setDataManifest] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    const { bookingDetail, jamaahAdditionalAdded } = useSelector(state => state.booking)

    const auth = useSelector(({auth}) => auth)
    const permissions = auth?.user?.permissions;

    const [additionals, setAdditionals] = useState([])
    const [isLoadingAdditionals, setIsLoadingAdditionals] = useState(false)

    const [equipments, setEquipments] = useState([])
    const [isLoadingEquipments, setIsLoadingEquipments] = useState(false)

    const [isEditAdditional, setIsEditAdditional] = useState(false)

    const [isDeleteAdditional, setIsDeleteAdditional] = useState(false)

    const [isUploading, setIsUploading] = useState(false)

    const [sendingFollowup, setSendingFollowup] = useState({
      loading: false,
      key: null
    })

    const [isUpdateManifest, setIsUpdateManifest] = useState(false)
    const [updateManifestKey, setUpdateManifestKey] = useState('pas_photo_4x6')
    const [updateManifestTitle, setUpdateManifestTitle] = useState("Pas Photo 4x6")

    const [additonalDataEdit, setAdditionalDataEdit] = useState({})
    const [additonalDataDelete, setAdditionalDataDelete] = useState({})
    const [isDeletingAdditional, setIsDeletingAdditional] = useState(false)

    const [isUpdateRoom, setIsUpdateRoom] = useState(false)
    
    const [isCancelJamaah, setIsCancelJamaah] = useState(false)
    const [isCancelingJamaah, setIsCancelingJamaah] = useState(false)

    const [isConfirmChangeStatusSeat, setIsConfirmChangeStatusSeat] = useState(false)
    const [isJamaahOrderStatusSeatId, setIsJamaahOrderStatusSeatId] = useState(null)
    const [isLoadingStatusSeat, setIsLoadingStatusSeat] = useState(false)


    const handleGetDataAdditionals = async () => {
        setIsLoadingAdditionals(true)
        const response = await handleGetDataAdditionalsAPI({
            orderNumber: params.orderNumber,
            jamaahOrderID: jamaahData?.id
        });
        setIsLoadingAdditionals(false)
        const { status, data } = response
  
        if(status === 200) {
            let newAdditionals = data.data
            
            newAdditionals.map(additional => {
                return {
                    ...additional,
                    key: additional.id
                }
            })

            setAdditionals(newAdditionals)
        } else {
            dispatch(handleError(data))
        }
    }

    const handleGetDataManifest = async () => {
        setIsLoading(true)
        const response = await handleGetDataManifestAPI({
            orderNumber: params.orderNumber,
            jamaahOrderID: jamaahData?.id
        });
        setIsLoading(false)
        const { status, data } = response

        if(status === 200) {
            setDataManifest(data.data)
        } else {
            dispatch(handleError(data))
        }
    }

    const handleGetDataEquipments = async () => {
        setIsLoadingEquipments(true)
        const response = await handleGetDataEquipmentsAPI({
            orderNumber: params.orderNumber,
            jamaahOrderID: jamaahData?.id
        });
        setIsLoadingEquipments(false)
        const { status, data } = response
  
        if(status === 200) {
            setEquipments(data.data)
        } else {
            dispatch(handleError(data))
        }
    }

    const handleCountPercent = (ready, total) => {
        return Math.round((ready / total) * 100)
    }

    const handleEditAdditional = (data) => {
      setIsEditAdditional(true)
      setAdditionalDataEdit(data)
    }

    const handleDeleteAdditional = (data) => {
      setIsDeleteAdditional(true)
      setAdditionalDataDelete(data)
    }

    const handleConfirmDeleteAdditional = async () => {
      setIsDeletingAdditional(true)
      const dataParams = {
        orderNumber: params.orderNumber,
        jamaahOrderID: jamaahData.id,
        additionalID: additonalDataDelete.paket_additional_id,
      }

      const response = await handleDeleteAdditionalByJamaahOrderAPI(dataParams)
      setIsDeletingAdditional(false)
      
      if(response.status === 200) {
        dispatch(handleSuccess(response))
        handleGetDataAdditionals()
        dispatch(handleGetDetailOrder(params.orderNumber))
        dispatch(handleGetDataJamaah(params.orderNumber))
        setIsDeleteAdditional(false)
      } else {
        dispatch(handleError(response.data))
      }

    }

    const handleConfirmCancelJamaah = async () => {
      setIsCancelingJamaah(true)
      const response = await handleCancelJamaahAPI(params.orderNumber, jamaahData.id)
      setIsCancelingJamaah(false)

      if(response.status === 200) {
        dispatch(handleSuccess(response))
        dispatch(handleGetDetailOrder(params.orderNumber))
        dispatch(handleGetDataJamaah(params.orderNumber))
        setIsCancelJamaah(false)
      } else {
        dispatch(handleError(response.data))
      }

    }

    const isOverDeadlineRegistration = () => {
        if(auth?.user?.access_data === 'self_branch') {
          const now = new Date()
          const deadline = new Date(bookingDetail.paket.deadline_registration_date)

          return now > deadline
        }
        
        return false
    }

    const handleRoomType = (roomType) => {
      roomType = roomType.replace('_bussiness', '(Busines Class)')
      roomType = roomType.replace('tripple', 'triple')

      return roomType.toUpperCase()
    }

    const handleClickFollowup = async (jamaahOrderID, key) => {
      setSendingFollowup({
        loading: true,
        key: key
      })
      const res = await handleSendFollowupToJamaahOrder(params.orderNumber, jamaahOrderID, key)
      setSendingFollowup({
        loading: false,
        key: null
      })
  
      if (res.status === 200) {
        dispatch(handleSuccess(res))
      } else {
        dispatch(handleError(res.data))
      }
  
    }

    const handleChangeStatuSeat = async (jamaahOrderId) => {
      setIsLoadingStatusSeat(true)

      const res = await updateStatusSeatAPI(params.orderNumber, jamaahOrderId)
      setIsLoadingStatusSeat(false)

      if(res.status === 200) {
        dispatch(handleSuccess(res))
        dispatch(handleGetDataJamaah(params.orderNumber))
        setIsConfirmChangeStatusSeat(false)
      } else {
        dispatch(handleError(res.data))
      }

    }

    useEffect(() => {
        if(isExpand) {
          handleGetDataManifest()
          handleGetDataAdditionals()
          handleGetDataEquipments()
        }
        
    }, [isExpand])

    useEffect(() => {
      if(jamaahAdditionalAdded.length > 0 && jamaahAdditionalAdded.includes(jamaahData.id)) {
        handleGetDataAdditionals()
      }
    }, [jamaahAdditionalAdded])

    return (
      <>
        <Popup
          open={isEditAdditional}
          handleClose={() => setIsEditAdditional(false)}
          title="Edit Additional"
          width="752px" 
          children={
            <EditAdditionalForm
              // packageID={}
              jamaahData={jamaahData}
              additionalData={additonalDataEdit}
              onChange={() => {
                handleGetDataAdditionals()
                dispatch(handleGetDetailOrder(params.orderNumber))
              }}
              />
          }
          />
        
        <Popup
          open={isUpdateRoom}
          handleClose={() => setIsUpdateRoom(false)}
          title={"Update Room"}
          width="900px" 
          children={
            <EditRoom 
              jamaahData={jamaahData}
              />
          }
          />

        <Popup
          open={isUpdateManifest}
          handleClose={() => setIsUpdateManifest(false)}
          title={updateManifestTitle}
          width="900px" 
          children={
            <ManifestForm 
              manifestKey={updateManifestKey}
              dataManifest={dataManifest}
              jamaahData={jamaahData}
              onUpdate={() => {
                handleGetDataManifest()
              }}
              />
          }
          />

        <Alert 
          open={isDeleteAdditional}
          title="Yakin akan menghapus additonal ini?"
          subtitle="Additonal yang akan terhapus dari jamaah order ini"
          handleClose={() => setIsDeleteAdditional(false)}
          handleContinue={() => handleConfirmDeleteAdditional()}
          text_accept="Yakin"
          text_cancel="Tidak"
          loading={isDeletingAdditional}
          />

        <Alert 
          open={isConfirmChangeStatusSeat}
          title="Yakin ingin merubah status seat?"
          subtitle="Perubahan status seat akan mempengaruhi total seat pada paket"
          handleClose={() => setIsConfirmChangeStatusSeat(false)}
          handleContinue={() => handleChangeStatuSeat(isJamaahOrderStatusSeatId)}
          text_accept="Yakin"
          text_cancel="Tidak"
          loading={isLoadingStatusSeat}
          />

            <Alert 
            open={isCancelJamaah}
            title="Yakin akan membatalkan jamaah ini?"
            subtitle="Jamaah akan dibatalkan didalam pesanan ini"
            handleClose={() => setIsCancelJamaah(false)}
            handleContinue={() => handleConfirmCancelJamaah()}
            text_accept="Yakin"
            text_cancel="Tidak"
            loading={isCancelingJamaah}
          />
        
        <div>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <div className="pink-bg-shine !p-4">
                <div className="flex flex-row justify-between">
                  <div className="text-[#FFFFFF]">Tipe Kamar:</div>
                  <div className="flex gap-4 items-center">
                    {permissions?.includes("update_booking-data") && (
                      <>
                        {bookingDetail?.payment_status !== 4 && (
                          <div 
                            onClick={() => {
                              if(!isOverDeadlineRegistration()) {
                                setIsUpdateRoom(true)
                              }
                            }}
                            className={`bg-[#FFFFFF] rounded-lg p-2 ${isOverDeadlineRegistration() ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
                            <img
                              className="w-[16px] h-[16px]"
                              src={IconEdit}
                              alt="user-img"
                            />
                          </div>
                        )}
                      </>
                    )}
                    {permissions?.includes("update_booking-data") && (
                      <>
                        {bookingDetail?.payment_status !== 4 && (
                          <div 
                            onClick={() => {
                              if(!isOverDeadlineRegistration()) {
                                setIsCancelJamaah(true)
                              }
                            }}
                            className={`bg-[#FFFFFF] rounded-lg p-2 ${isOverDeadlineRegistration() ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
                            <img
                              className="w-[16px] h-[16px]"
                              src={IconTrash}
                              alt="user-img"
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="flex flex-row justify-between mt-4 items-center">
                  <div className="font-semibold text-[40px] txt-yellow">
                    {handleRoomType(jamaahData?.room_type)}
                  </div>
                  <Badge
                    label={jamaahData?.name_package_starting?.toUpperCase()}
                    customClass="h-fit !px-4 !py-[6px] !text-[16px] !font-semibold drop-bg-50"
                    color={jamaahData?.package_starting?.text_color}
                  />
                </div>
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="pink-bg-shine !p-4">
                <div className="flex flex-row justify-between">
                  <div className="text-white text-[16px]">Follow Up:</div>
                  {/* {permissions?.includes("update_booking-data") && (
                    <div 
                      className="bg-white p-2 rounded-lg cursor-pointer">
                        <img
                          className="w-[18px] h-[18px]"
                          src={IconEdit}
                          alt="user-img"
                        />
                    </div>
                  )} */}
                </div>

                <div className="flex flex-row gap-14 items-center mt-6">
                  <div>
                    <div className="flex gap-4">
                        <div className="flex flex-row">
                            <img src={IconSMS} alt="sms" />
                            <div className="ml-1 text-white">{jamaahData?.jamah_email ?? '-'}</div>
                        </div>
                        <div className="flex flex-row">
                            <img src={IconWhatsapp} alt="whatsapp" />
                        <div className="ml-1 text-white">{jamaahData?.jamaah_phone ?? '-'}</div>
                        </div>
                    </div>
                    <div className="flex flex-row mt-2">
                      <Buttons
                        text="1"
                        customClass="btn-white-light btn-small !w-fit"
                        icon={FollowUpSenderIcon}
                        disabled={(bookingDetail.payment_status != 0 || !bookingDetail.payment_dp)}
                        onClick={() => handleClickFollowup(jamaahData.id, 'dp')}
                        loadingIcon={true}
                        loading={sendingFollowup.loading && sendingFollowup.key === 'dp'}
                      />
                      <Buttons
                        text="2"
                        customClass="btn-white-light btn-small !w-fit ml-1"
                        icon={FollowUpSenderIcon}
                        disabled={(bookingDetail.payment_status === 0 || bookingDetail.payment_status === 4) || jamaahData.dokumen_terisi_persen === 100}
                        onClick={() => handleClickFollowup(jamaahData.id, 'manifest')}
                        loadingIcon={true}
                        loading={sendingFollowup.loading && sendingFollowup.key === 'manifest'}
                      />
                      <Buttons
                        text="3"
                        customClass="btn-white-light btn-small !w-fit ml-1"
                        icon={FollowUpSenderIcon}
                        disabled={(bookingDetail.payment_status === 0 || bookingDetail.payment_status === 4 || bookingDetail.payment_status === 3)}
                        onClick={() => handleClickFollowup(jamaahData.id, 'payment')}
                        loadingIcon={true}
                        loading={sendingFollowup.loading && sendingFollowup.key === 'payment'}
                      />
                      {/* <Buttons
                        text="4"
                        customClass="btn-white-light btn-small !w-fit ml-1"
                        icon={FollowUpSenderIcon}
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item md={2}>
              <div className="pink-bg-shine !p-4">
                <div className="">
                  <div className="text-white text-[16px]">Status Seat :</div>
                  
                  <div className="flex flex-row items-center mt-[58px]">
                    <Switch
                      checked={!jamaahData?.with_ticket}
                      onChange={() => {
                        setIsConfirmChangeStatusSeat(true)
                        setIsJamaahOrderStatusSeatId(jamaahData?.id)
                      }}
                    />
                    <div className="text-sm font-general text-[#fff] ml-2 w-full">
                      Tanpa Tiket
                    </div>
                  </div>
                </div>
                </div>
            </Grid>
          </Grid>

          <div className="mt-4">
            <Grid container spacing={2}>
              <Grid item md={6}>
                <div className="bg-white soft-shadow rounded-2xl p-4">
                  <div className="flex flex-row justify-between">
                    <div className="text-[16px] font-semibold">
                      Pas Foto 4x6
                    </div>
                    <div 
                      onClick={() => {
                        setIsUpdateManifest(true)
                        setUpdateManifestKey('pas_photo_4x6')
                        setUpdateManifestTitle("Pas Photo 4x6")
                      }}
                      className="bg-[#FFFFFF] rounded-lg p-2 cursor-pointer">
                      {permissions?.includes('update_manifest') && bookingDetail?.payment_status !== 4 ? (
                        <img
                          className="w-[18px] h-[18px]"
                          src={IconEdit}
                          alt="user-img"
                        />
                      ) : (
                        <Eye 
                          size={20}
                          />
                      )}
                    </div>
                  </div>
                  <Progressbars 
                    containerClass="mt-5" 
                    percent={
                        handleCountPercent(dataManifest?.manifest?.pas_photo_4x6?.total_document_uploaded, dataManifest?.manifest?.pas_photo_4x6?.total_document)
                    }
                    customInfoClass="absolute !top-[-24px] !right-0" 
                    info={`
                    ${dataManifest?.manifest?.pas_photo_4x6?.total_document_uploaded}/
                    ${dataManifest?.manifest?.pas_photo_4x6?.total_document}`} />
                </div>
              </Grid>
              <Grid item md={6}>
                <div className="bg-white soft-shadow rounded-2xl p-4">
                  <div className="flex flex-row justify-between">
                    <div className="text-[16px] font-semibold">
                      Identitas KTP
                    </div>
                    <div 
                      onClick={() => {
                        setIsUpdateManifest(true)
                        setUpdateManifestKey('ktp')
                        setUpdateManifestTitle("Identitas KTP")
                      }}
                      className="bg-[#FFFFFF] rounded-lg p-2 cursor-pointer">
                      {permissions?.includes('update_manifest') && bookingDetail?.payment_status !== 4 ? (
                        <img
                          className="w-[18px] h-[18px]"
                          src={IconEdit}
                          alt="user-img"
                        />
                      ) : (
                        <Eye 
                          size={20}
                          />
                      )}
                    </div>
                  </div>
                  <Progressbars 
                    containerClass="mt-5" 
                    percent={
                        handleCountPercent(dataManifest?.manifest?.ktp?.total_document_uploaded, dataManifest?.manifest?.ktp?.total_document)
                    }
                    customInfoClass="absolute !top-[-24px] !right-0" 
                    info={`
                        ${dataManifest?.manifest?.ktp?.total_document_uploaded}/
                        ${dataManifest?.manifest?.ktp?.total_document}`
                    }
                    />
                </div>
              </Grid>
              <Grid item md={6}>
                <div className="bg-white soft-shadow rounded-2xl p-4">
                  <div className="flex flex-row justify-between">
                    <div className="text-[16px] font-semibold">
                      Passport
                    </div>
                    <div 
                      onClick={() => {
                        setIsUpdateManifest(true)
                        setUpdateManifestKey('passport')
                        setUpdateManifestTitle("Passport")
                      }}
                      className="bg-[#FFFFFF] rounded-lg p-2 cursor-pointer">
                      {permissions?.includes('update_manifest') && bookingDetail?.payment_status !== 4 ? (
                        <img
                          className="w-[18px] h-[18px]"
                          src={IconEdit}
                          alt="user-img"
                        />
                      ) : (
                        <Eye 
                          size={20}
                          />
                      )}
                    </div>
                  </div>
                  <Progressbars 
                    containerClass="mt-5" 
                    percent={
                        handleCountPercent(dataManifest?.manifest?.passport?.total_document_uploaded, dataManifest?.manifest?.passport?.total_document)
                    }
                    customInfoClass="absolute !top-[-24px] !right-0" 
                    info={`
                        ${dataManifest?.manifest?.passport?.total_document_uploaded}/
                        ${dataManifest?.manifest?.passport?.total_document}`
                    }
                    />
                </div>
              </Grid>
              <Grid item md={6}>
                <div className="bg-white soft-shadow rounded-2xl p-4">
                  <div className="flex flex-row justify-between">
                    <div className="text-[16px] font-semibold">
                      Informasi Kontak
                    </div>
                    <div 
                      onClick={() => {
                        setIsUpdateManifest(true)
                        setUpdateManifestKey('contact')
                        setUpdateManifestTitle("Informasi Kontak")
                      }}
                      className="bg-[#FFFFFF] rounded-lg p-2 cursor-pointer">
                      {permissions?.includes('update_manifest') && bookingDetail?.payment_status !== 4 ? (
                        <img
                          className="w-[18px] h-[18px]"
                          src={IconEdit}
                          alt="user-img"
                        />
                      ) : (
                        <Eye 
                          size={20}
                          />
                      )}
                    </div>
                  </div>
                  <Progressbars 
                    containerClass="mt-5" 
                    percent={
                        handleCountPercent(dataManifest?.manifest?.contact?.total_document_uploaded, dataManifest?.manifest?.contact?.total_document)
                    }
                    customInfoClass="absolute !top-[-24px] !right-0" 
                    info={`
                        ${dataManifest?.manifest?.contact?.total_document_uploaded}/
                        ${dataManifest?.manifest?.contact?.total_document}`
                    }
                    />
                </div>
              </Grid>
              <Grid item md={6}>
                <div className="bg-white soft-shadow rounded-2xl p-4">
                  <div className="flex flex-row justify-between">
                    <div className="text-[16px] font-semibold">Akta Kelahiran</div>
                    <div 
                      onClick={() => {
                        setIsUpdateManifest(true)
                        setUpdateManifestKey('akta_kelahiran')
                        setUpdateManifestTitle("Akta Kelahiran")
                      }}
                      className="bg-[#FFFFFF] rounded-lg p-2 cursor-pointer">
                      {permissions?.includes('update_manifest') && bookingDetail?.payment_status !== 4 ? (
                        <img
                          className="w-[18px] h-[18px]"
                          src={IconEdit}
                          alt="user-img"
                        />
                      ) : (
                        <Eye 
                          size={20}
                          />
                      )}
                    </div>
                  </div>
                  <Progressbars 
                    containerClass="mt-5" 
                    percent={
                        handleCountPercent(dataManifest?.manifest?.akta_kelahiran?.total_document_uploaded, dataManifest?.manifest?.akta_kelahiran?.total_document)
                    }
                    customInfoClass="absolute !top-[-24px] !right-0" 
                    info={`
                        ${dataManifest?.manifest?.akta_kelahiran?.total_document_uploaded}/
                        ${dataManifest?.manifest?.akta_kelahiran?.total_document}`
                    } />
                </div>
              </Grid>
              <Grid item md={6}>
                <div className="bg-white soft-shadow rounded-2xl p-4">
                  <div className="flex flex-row justify-between">
                    <div className="text-[16px] font-semibold">
                      Informasi Keluarga
                    </div>
                    <div 
                      onClick={() => {
                        setIsUpdateManifest(true)
                        setUpdateManifestKey('family')
                        setUpdateManifestTitle("Info Keluarga")
                      }}
                      className="bg-[#FFFFFF] rounded-lg p-2 cursor-pointer">
                      {permissions?.includes('update_manifest') && bookingDetail?.payment_status !== 4 ? (
                        <img
                          className="w-[18px] h-[18px]"
                          src={IconEdit}
                          alt="user-img"
                        />
                      ) : (
                        <Eye 
                          size={20}
                          />
                      )}
                    </div>
                  </div>
                  <Progressbars 
                    containerClass="mt-5" 
                    percent={
                        handleCountPercent(dataManifest?.manifest?.family?.total_document_uploaded, dataManifest?.manifest?.family?.total_document)
                    }
                    customInfoClass="absolute !top-[-24px] !right-0" 
                    info={`
                        ${dataManifest?.manifest?.family?.total_document_uploaded}/
                        ${dataManifest?.manifest?.family?.total_document}`
                    } 
                    />
                </div>
              </Grid>
              {/* <Grid item md={6}>
                <div className="bg-white soft-shadow rounded-2xl p-4">
                  <div className="flex flex-row justify-between">
                    <div className="text-[16px] font-semibold">
                      Vaksin Covid
                    </div>
                    <div 
                      onClick={() => {
                        setIsUpdateManifest(true)
                        setUpdateManifestKey('vaccine_covid')
                        setUpdateManifestTitle("Vaksin Covid")
                      }}
                      className="bg-[#FFFFFF] rounded-lg p-2 cursor-pointer">
                      {permissions?.includes('update_manifest') && bookingDetail?.payment_status !== 4 ? (
                        <img
                          className="w-[18px] h-[18px]"
                          src={IconEdit}
                          alt="user-img"
                        />
                      ) : (
                        <Eye 
                          size={20}
                          />
                      )}
                    </div>
                  </div>
                  <Progressbars 
                     containerClass="mt-5" 
                    percent={
                        handleCountPercent(dataManifest?.manifest?.vaccine_covid?.total_document_uploaded, dataManifest?.manifest?.vaccine_covid?.total_document)
                    }
                    customInfoClass="absolute !top-[-24px] !right-0" 
                    info={`
                        ${dataManifest?.manifest?.vaccine_covid?.total_document_uploaded}/
                        ${dataManifest?.manifest?.vaccine_covid?.total_document}`
                    } 
                    />
                </div>
              </Grid> */}
              <Grid item md={6}>
                <div className="bg-white soft-shadow rounded-2xl p-4">
                  <div className="flex flex-row justify-between">
                    <div className="text-[16px] font-semibold">Meningitis</div>
                    <div 
                      onClick={() => {
                        setIsUpdateManifest(true)
                        setUpdateManifestKey('meningitis_vaccine')
                        setUpdateManifestTitle("Vaksin Meningitis")
                      }}
                      className="bg-[#FFFFFF] rounded-lg p-2 cursor-pointer">
                      {permissions?.includes('update_manifest') && bookingDetail?.payment_status !== 4 ? (
                        <img
                          className="w-[18px] h-[18px]"
                          src={IconEdit}
                          alt="user-img"
                        />
                      ) : (
                        <Eye 
                          size={20}
                          />
                      )}
                    </div>
                  </div>
                  <Progressbars 
                    containerClass="mt-5" 
                    percent={
                        handleCountPercent(dataManifest?.manifest?.meningitis_vaccine?.total_document_uploaded, dataManifest?.manifest?.meningitis_vaccine?.total_document)
                    }
                    customInfoClass="absolute !top-[-24px] !right-0" 
                    info={`
                        ${dataManifest?.manifest?.meningitis_vaccine?.total_document_uploaded}/
                        ${dataManifest?.manifest?.meningitis_vaccine?.total_document}`
                    } />
                </div>
              </Grid>
              {/* <Grid item md={6}>
                <div className="bg-white soft-shadow rounded-2xl p-4">
                  <div className="flex flex-row justify-between">
                    <div className="text-[16px] font-semibold">BPJS</div>
                    <div 
                      onClick={() => {
                        setIsUpdateManifest(true)
                        setUpdateManifestKey('bpjs')
                        setUpdateManifestTitle("BPJS Kesehatan")
                      }}
                      className="bg-[#FFFFFF] rounded-lg p-2 cursor-pointer">
                      {permissions?.includes('update_manifest') && bookingDetail?.payment_status !== 4 ? (
                        <img
                          className="w-[18px] h-[18px]"
                          src={IconEdit}
                          alt="user-img"
                        />
                      ) : (
                        <Eye 
                          size={20}
                          />
                      )}
                    </div>
                  </div>
                  <Progressbars 
                    containerClass="mt-5" 
                    percent={
                        handleCountPercent(dataManifest?.manifest?.bpjs?.total_document_uploaded, dataManifest?.manifest?.bpjs?.total_document)
                    }
                    customInfoClass="absolute !top-[-24px] !right-0" 
                    info={`
                        ${dataManifest?.manifest?.bpjs?.total_document_uploaded}/
                        ${dataManifest?.manifest?.bpjs?.total_document}`
                    } />
                </div>
            </Grid> */}
            </Grid>

            <Tab
              containerClass="mt-4"
              defaultActiveKey="1"
              items={[
                {
                  label: "Additional",
                  key: "1",
                  children: 
                    <AdditionalTable 
                        additionals={additionals}
                        isLoading={isLoadingAdditionals}
                        handleEdit={handleEditAdditional}
                        handleDelete={handleDeleteAdditional}
                        />,
                },
                {
                  label: "Perlengkapan",
                  key: "2",
                  children: <JamaahEquipment 
                                isLoading={isLoadingEquipments}
                                equipments={equipments}
                                jamaahData={jamaahData}
                                handleUpdateSuccess={handleGetDataEquipments}
                            />,
                },
              ]}
            />
          </div>
        </div>
      </>
    )
}

export default DataJamaah